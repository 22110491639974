<template>
  <router-view v-if="loadingComplete"></router-view>
  <!--begin::Loading Spinner-->
  <div class="row" v-else>
    <div class="col-xxl-12 min-vh-100">
      <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
        <div class="card-body my-4 pd-12">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner class="main-card-loader" label="Loading..."></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Loading Spinner-->
</template>

<script>
import { mapGetters } from "vuex";
import { POPULATE_ACTIVITY_LIST } from "@/core/services/store/activity/activity.module";
import { POPULATE_PROJECT_ROOM_SETUP_LIST } from "@/core/services/store/project/project_room_setup.module";

export default {
  name: "activity",
  components: {},

  directives: {},

  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 2
      },
      error: {
        active: false,
        message: ""
      }
    };
  },

  watch: {
    $route: function(to, from) {
      if (to !== from) {
        this.pageLoader.componentsRequired--;
        this.fetchActivities();
      }
    }
  },

  mounted() {
    this.fetchActivities();
    this.fetchProjectRooms();
  },

  methods: {
    fetchActivities() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          scope: "input",
          domain: window.location.host.split(".")[0],
          paginate: false,
          activityType: "with",
          activityStage: "with",
          projectSegments: "with",
          activityStimuli: "with",
          stimulusAssets: "with",
          engagementStimuliAssets: 'with',
          starts_on: "ASC"
        }
      };
      this.$store
        .dispatch(POPULATE_ACTIVITY_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity list, please check back later or contact the helpdesk";
        });
    },
    fetchProjectRooms: function() {
      let payload = {
        projectId: this.projectInfo.id,
        data: {
          params: {
            projectRooms: "with",
            roomSetup: "with"
          }
        }
      };
      this.$store
        .dispatch(POPULATE_PROJECT_ROOM_SETUP_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity list, please check back later or contact the helpdesk";
        });
    }
  },

  computed: {
    ...mapGetters(["projectInfo"]),
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
